(function () {
    'use strict';

    angular
        .module('smartermail')
        .controller('aliasSummaryController', aliasSummaryController);

    function aliasSummaryController($scope, $mdDialog, name, targets, includeAllDomainUsers, userNames, isChatAliasList) {
        $scope.name = name;
        $scope.targets = targets;
        $scope.userNames = userNames;
        $scope.isAlias = true;
        $scope.isChatAliasList = isChatAliasList || false;
        if ($scope.isChatAliasList) {
            $scope.isAlias = false;
        }
        if (targets != null) { 
            $scope.aliasTargets = {};
            for (var i = 0; i < targets.length; i++) {
                $scope.aliasTargets[targets[i]] = userNames[i];
            }
        }
        $scope.includeAllDomainUsers = includeAllDomainUsers;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    };
})();