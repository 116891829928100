(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userSettingsSchedulingController", userSettingsSchedulingController);

    function userSettingsSchedulingController($rootScope, $scope, $http, $q, $mdDialog, errorHandling, successHandling, $translate, $timeout,
                                              $filter, coreLicensing, coreDataSettings, coreDataCalendar, userTimeService, preferencesStorage, userDataService, coreData) {

        var vm = this;
        vm.data = {
            profileUrl: '/interface/img/ST_User_Cir.svg',
            profileName: '',
        };
        vm.randomUrlString = Math.random().toString();

        vm.showAppointmentTypeError = false; // Initialize the error tracking variable

        vm.daysOfWeek = [
            { name: "Sunday", translate: "SUNDAY" },
            { name: "Monday", translate: "MONDAY" },
            { name: "Tuesday", translate: "TUESDAY" },
            { name: "Wednesday", translate: "WEDNESDAY" },
            { name: "Thursday", translate: "THURSDAY" },
            { name: "Friday", translate: "FRIDAY" },
            { name: "Saturday", translate: "SATURDAY" }
        ];

        vm.dateStarts = [];
        vm.dateEnds = [];
        vm.dateEnableds = [];
        vm.customFields = [{ name: "", required: false }];
        vm.appointmentTypes = [];
        vm.conflictCalendars = [];

        vm.calendars = [];
        vm.sourceCalendars = [];
        vm.calendar = "";
        vm.privateAppointments = false;
        vm.enablePassword = false;
        vm.minBetween = 3;
        vm.leadTime = 0;
        vm.isDisabled = false;
        vm.enableScheduling = false;
        vm.pageDescription = "";
        vm.embedCode = '';
        vm.displayColor = "#fe6c6c";
        vm.titleColor = "#00e08a";
        vm.backgroundColor = "#005fdb";
        vm.calander = "";
        vm.baseCalendarLink = coreData.baseUrl + '/interface/schedule-appointment/';
        vm.calendarLink = "";
        vm.schedulingGuid = "";
        vm.publicLinkInfo = "";
        vm.schedulingPassword = "";
        vm.bookingHorizonLimit = 0;
        vm.icsDescription = "";
        vm.description = "";
        vm.isUsingDifferentDescription = false;
        vm.ignoreEndChange = false;
        vm.onEnableSchedulingChanged = onEnableSchedulingChanged;
        vm.previousSchedulingEnabled = false;

        // functions
        vm.reloadState = reloadState;
        vm.save = save;
        vm.newAppointment = newAppointment;
        vm.editAppointment = editAppointment;
        vm.newCalendarConflict = newCalendarConflict;
        vm.editCalendarConflict = editCalendarConflict;
        vm.getDuration = getDuration;
        vm.onFieldBlur = onFieldBlur;
        vm.onFieldChanged = onFieldChanged;
        vm.fieldDelete = fieldDelete;
        vm.changeDate = changeDate;
        vm.getAppointmentName = getAppointmentName;
        vm.hideFieldDelete = hideFieldDelete;

        getSources();
        activate();

        /////////////////////

        // Watchers for dateStarts and dateEnds arrays
        $scope.$watch("pageCtrl.dateStarts[0]", function () { vm.changeDate("Sunday") });
        $scope.$watch("pageCtrl.dateStarts[1]", function () { vm.changeDate("Monday") });
        $scope.$watch("pageCtrl.dateStarts[2]", function () { vm.changeDate("Tuesday") });
        $scope.$watch("pageCtrl.dateStarts[3]", function () { vm.changeDate("Wednesday") });
        $scope.$watch("pageCtrl.dateStarts[4]", function () { vm.changeDate("Thursday") });
        $scope.$watch("pageCtrl.dateStarts[5]", function () { vm.changeDate("Friday") });
        $scope.$watch("pageCtrl.dateStarts[6]", function () { vm.changeDate("Saturday") });
        $scope.$watch("pageCtrl.dateEnds[0]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Sunday") } else { vm.ignoreEndChange = false } });
        $scope.$watch("pageCtrl.dateEnds[1]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Monday") } else { vm.ignoreEndChange = false } });
        $scope.$watch("pageCtrl.dateEnds[2]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Tuesday") } else { vm.ignoreEndChange = false } });
        $scope.$watch("pageCtrl.dateEnds[3]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Wednesday") } else { vm.ignoreEndChange = false } });
        $scope.$watch("pageCtrl.dateEnds[4]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Thursday") } else { vm.ignoreEndChange = false } });
        $scope.$watch("pageCtrl.dateEnds[5]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Friday") } else { vm.ignoreEndChange = false } });
        $scope.$watch("pageCtrl.dateEnds[6]", function () { if (!vm.ignoreEndChange) { vm.changeDate("Saturday") } else { vm.ignoreEndChange = false } });

        // Watcher to reset error state when appointment types change
        $scope.$watch(function() { return vm.appointmentTypes.length; }, function(newVal) {
            if (newVal > 0) {
                vm.showAppointmentTypeError = false;
            }
        });

        function activate(reset) {
            coreData.init().then(function () {
                userTimeService.init().then(init, errorHandling.report);

                coreData.user.refreshPictureUrl().then(onPictureRefreshed, function () { });
            }, errorHandling.report);

            function onPictureRefreshed() {
                vm.data.profileUrl = coreData.user.pictureUrl;
                vm.randomUrlString = Math.random().toString();
                $scope.$applyAsync();
            }
        }

        function loadSettings() {
            $http.get('~/api/v1/settings/user-mail').then(onSettingsLoaded, function () { });
        }

        function tzMomentTimeSpan(dt) {
            var split = dt.split(":");
            return moment().hours(split[0]).minutes(split[1]).seconds(split[2]).toDate();
        }

        async function init() {
            await getSources();
            loadSettings();
        }
        function onEnableSchedulingChanged() {
            if (vm.enableScheduling) {
                var appointmentExists = vm.appointmentTypes.length !== 0;
                var translatedName = $translate.instant("SCHEDULE_APPOINTMENT");
                if (!appointmentExists) {
                    vm.appointmentTypes.push({
                        name: translatedName,
                        duration: 1,
                        location: "",
                        isOnline: false,
                        bookingHorizonLimit: 0,
                        isUsingDifferentDescription: false,
                        description: "",
                        icsDescription: ""
                    });
                    vm.appointmentTypes.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    if ($scope.pageForm) $scope.pageForm.$setDirty();
                }
            } else {
                // Remove any appointment types added when scheduling was enabled
                vm.appointmentTypes = [];
                // Reset the form's dirty state since we've reverted changes 
                if (vm.previousSchedulingEnabled === vm.enableScheduling) {
                    if ($scope.pageForm) $scope.pageForm.$setPristine();
                }
            }
        }

        function onSettingsLoaded(result) {
            vm.dateStarts = [];
            vm.dateEnds = [];
            var scheduleSettings = result.data.userMailSettings.schedulingSettings;

            vm.publicLinkInfo = scheduleSettings.publicLinkInfo;
            vm.schedulingGuid = scheduleSettings.schedulingGuid;
            vm.enableScheduling = scheduleSettings.enabled;
            //I need to keep track of the previous state to know if I should make the pageform pristine or not for saving the enable/disable state
            vm.previousSchedulingEnabled = scheduleSettings.enabled;
            vm.privateAppointments = scheduleSettings.privateAppointment;
            vm.enablePassword = scheduleSettings.passwordProtected;
            vm.schedulingPassword = scheduleSettings.scheduleSettings;
            vm.calendar = scheduleSettings.calendarId;
            vm.leadTime = scheduleSettings.leadTime;
            vm.minBetween = scheduleSettings.minutesBetween;
            vm.pageDescription = scheduleSettings.pageDescription;
            vm.backgroundColor = scheduleSettings.backgroundColor;
            vm.titleColor = scheduleSettings.appointmentTitleColor;
            vm.displayColor = scheduleSettings.displayNameColor;
            vm.dateStarts.push(tzMomentTimeSpan(scheduleSettings.sunday.start));
            vm.dateStarts.push(tzMomentTimeSpan(scheduleSettings.monday.start));
            vm.dateStarts.push(tzMomentTimeSpan(scheduleSettings.tuesday.start));
            vm.dateStarts.push(tzMomentTimeSpan(scheduleSettings.wednesday.start));
            vm.dateStarts.push(tzMomentTimeSpan(scheduleSettings.thursday.start));
            vm.dateStarts.push(tzMomentTimeSpan(scheduleSettings.friday.start));
            vm.dateStarts.push(tzMomentTimeSpan(scheduleSettings.saturday.start));

            vm.dateEnds.push(tzMomentTimeSpan(scheduleSettings.sunday.end));
            vm.dateEnds.push(tzMomentTimeSpan(scheduleSettings.monday.end));
            vm.dateEnds.push(tzMomentTimeSpan(scheduleSettings.tuesday.end));
            vm.dateEnds.push(tzMomentTimeSpan(scheduleSettings.wednesday.end));
            vm.dateEnds.push(tzMomentTimeSpan(scheduleSettings.thursday.end));
            vm.dateEnds.push(tzMomentTimeSpan(scheduleSettings.friday.end));
            vm.dateEnds.push(tzMomentTimeSpan(scheduleSettings.saturday.end));

            vm.dateEnableds.push(scheduleSettings.sunday.enabled);
            vm.dateEnableds.push(scheduleSettings.monday.enabled);
            vm.dateEnableds.push(scheduleSettings.tuesday.enabled);
            vm.dateEnableds.push(scheduleSettings.wednesday.enabled);
            vm.dateEnableds.push(scheduleSettings.thursday.enabled);
            vm.dateEnableds.push(scheduleSettings.friday.enabled);
            vm.dateEnableds.push(scheduleSettings.saturday.enabled);

            vm.customFields = scheduleSettings.schedulingFields;
            vm.appointmentTypes = scheduleSettings.appointmentTypes;
            vm.appointmentTypes.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            vm.conflictCalendars = scheduleSettings.conflictCalendars;
            vm.conflictCalendars.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });

            vm.data.emailAddress = coreData.user.emailAddress;
            vm.data.contactInfo = angular.copy(result.data.userMailSettings.userContactInfo);

            vm.calendars = [];
            angular.forEach(vm.sourceCalendars, function (calendar) {
                if (calendar.owner == userDataService.user.username) {
                    vm.calendars.push(calendar);
                }
            });

            if (!vm.calendar) {
                var defaultCalendar = $filter('filter')(vm.calendars, { 'isPrimary': true })
                if (defaultCalendar)
                    vm.calendar = defaultCalendar[0].id;
            }

            if (vm.customFields == undefined) {
                vm.customFields = [{ name: "", required: false }];
            }
            else if (vm.customFields.length < 5) {
                vm.customFields.push({ name: "", required: false });
            }

            if (!vm.publicLinkInfo.startsWith(vm.baseCalendarLink))
                vm.calendarLink = vm.baseCalendarLink + vm.publicLinkInfo + "#/";
            else
                vm.calendarLink = vm.publicLinkInfo;
            vm.embedCode = '<iframe src="' + vm.calendarLink + '"style="width: 100%; height: 100%;" frameborder="0" scrolling="yes"></iframe>';
        }

        $scope.copyLink = function () {
            $('#publicLink').select().trigger("focus");
            document.execCommand('copy');
            successHandling.report("COPIED_TO_CLIPBOARD");
        };

        $scope.copyEmbed = function () {
            $('#embeddedCode').select().trigger("focus");
            document.execCommand('copy');
            successHandling.report("COPIED_TO_CLIPBOARD");
        };

        function reloadState(form) {
            activate(true);
            form.$setPristine();
        }

        function onFieldBlur(last, index, form) {
            var field = form["field" + index].$viewValue;
            if ((!field || field.length === 0) && !last)
                vm.customFields.splice(index, 1);
        }

        function onFieldChanged(last, index, form) {
            var field = form["field" + index].$viewValue;
            if (field && field.length > 0 && last && vm.customFields.length < 5)
                vm.customFields.push({ name: "", required: false });
        }

        function fieldDelete(index, form) {
            form.$setDirty();
            vm.customFields.splice(index, 1);
            if (vm.customFields.length == 0 || vm.customFields[vm.customFields.length - 1].name != "")
                vm.customFields.push({ name: "", required: false });
        }

        function hideFieldDelete(index) {
            if (vm.customFields.length == 1 && vm.customFields[0].name == "" && index == 0)
                return true;
            return false;
        }

        function save(event, form) {
            if (!form.$valid)
                return;
            if (vm.calendar == undefined || vm.calendar == "")
                return;
            if (vm.appointmentTypes.length <= 0 && vm.enableScheduling) {
                vm.showAppointmentTypeError = true; // Set error state
                return;
            } else {
                vm.showAppointmentTypeError = false; // Reset error state
            }
            var lastFieldIndex = vm.customFields.length - 1;
            if (vm.customFields[lastFieldIndex].name == "")
                vm.customFields.splice(lastFieldIndex, 1);

            vm.conflictCalendars = vm.conflictCalendars.filter(function (obj) {
                return obj.id !== vm.calendar;
            });

            var params = {
                userMailSettings: {
                    schedulingSettings: {
                        enabled: vm.enableScheduling,
                        privateAppointment: vm.privateAppointments,
                        passwordProtected: vm.enablePassword,
                        schedulingPassword: vm.schedulingPassword,
                        schedulingGuid: vm.schedulingGuid,
                        publicLinkInfo: vm.publicLinkInfo,
                        calendarId: vm.calendar,
                        leadTime: vm.leadTime,
                        minutesBetween: vm.minBetween,
                        pageDescription: vm.pageDescription,
                        backgroundColor: vm.backgroundColor,
                        appointmentTitleColor: vm.titleColor,
                        displayNameColor: vm.displayColor,
                        sunday: { start: moment(vm.dateStarts[0]).format("HH:mm:ss"), end: moment(vm.dateEnds[0]).format("HH:mm:ss"), enabled: vm.dateEnableds[0] },
                        monday: { start: moment(vm.dateStarts[1]).format("HH:mm:ss"), end: moment(vm.dateEnds[1]).format("HH:mm:ss"), enabled: vm.dateEnableds[1] },
                        tuesday: { start: moment(vm.dateStarts[2]).format("HH:mm:ss"), end: moment(vm.dateEnds[2]).format("HH:mm:ss"), enabled: vm.dateEnableds[2] },
                        wednesday: { start: moment(vm.dateStarts[3]).format("HH:mm:ss"), end: moment(vm.dateEnds[3]).format("HH:mm:ss"), enabled: vm.dateEnableds[3] },
                        thursday: { start: moment(vm.dateStarts[4]).format("HH:mm:ss"), end: moment(vm.dateEnds[4]).format("HH:mm:ss"), enabled: vm.dateEnableds[4] },
                        friday: { start: moment(vm.dateStarts[5]).format("HH:mm:ss"), end: moment(vm.dateEnds[5]).format("HH:mm:ss"), enabled: vm.dateEnableds[5] },
                        saturday: { start: moment(vm.dateStarts[6]).format("HH:mm:ss"), end: moment(vm.dateEnds[6]).format("HH:mm:ss"), enabled: vm.dateEnableds[6] },
                        schedulingFields: vm.customFields,
                        appointmentTypes: vm.appointmentTypes,
                        conflictCalendars: vm.conflictCalendars,
                        bookingHorizonLimit: vm.bookingHorizonLimit,
                        isUsingDifferentDescription: vm.isUsingDifferentDescription,
                        description: vm.description,
                        icsDescription: vm.icsDescription
                    }
                }
            };

            var jsonParam = JSON.stringify(params);

            $http.post("~/api/v1/settings/user-mail", jsonParam)
                .then(onSuccess, errorHandling.report);

            function onSuccess() {
                form.$setPristine();
                vm.showAppointmentTypeError = false; // Ensure error state is reset on successful save
                if (vm.customFields.length < 5)
                    vm.customFields.push({ name: "", required: false });
            }
        }

        function getDuration(setDuration) {
            var durationMinutes = "";
            switch (setDuration) {
                case 0:
                    return $filter("translate")("REMINDER_MINUTES", { numMinutes: 15 });
                case 1:
                    return $filter("translate")("REMINDER_MINUTES", { numMinutes: 30 });
                case 2:
                    return $filter("translate")("REMINDER_MINUTES", { numMinutes: 45 });
                case 3:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 1 });
                case 4:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 2 });
                case 5:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 3 });
                case 6:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 4 });
                case 7:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 5 });
                case 8:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 6 });
                case 9:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 7 });
                case 10:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 8 });
                case 11:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 9 });
                case 12:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 10 });
                case 13:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 11 });
                case 14:
                    return $filter("translate")("REMINDER_HOURS", { numHours: 12 });
            }
            return durationMinutes;
        }

        function changeDate(day) {
            var index;
            switch (day) {
                case "Sunday": index = 0; break;
                case "Monday": index = 1; break;
                case "Tuesday": index = 2; break;
                case "Wednesday": index = 3; break;
                case "Thursday": index = 4; break;
                case "Friday": index = 5; break;
                case "Saturday": index = 6; break;
                default: return;
            }

            var startDate = moment(vm.dateStarts[index]);
            var endDate = moment(vm.dateEnds[index]);
            if (startDate.hour() >= endDate.hour()) {
                vm.dateEnds[index] = moment(vm.dateEnds[index]).hour(moment(vm.dateStarts[index]).hour()).toDate();
                vm.ignoreEndChange = true;
            }
            if (startDate.hour() === endDate.hour() && startDate.minute() >= endDate.minute()) {
                vm.dateEnds[index] = (endDate.minute(startDate.minute())).toDate();
                vm.ignoreEndChange = true;
            }
        }

        function getSources() {
            return $http.get("~/api/v1/calendars/sources")
                .then(onGetSourcesSuccess, onGetSourcesFailure);
        }

        function onGetSourcesSuccess(result) {
            vm.sourceCalendars = result.data.calendars;
            angular.forEach(vm.sourceCalendars, function (source) {
                source.isVisible = preferencesStorage.getSourceVisibility("calendars", source);
                source.name = source.name.replace("~CAL_SHARE|", '');
                const split = source.name.split("|");
                if (split.length > 1) {
                    split.pop();
                    source.name = split.join("|");
                }
            });

            vm.sourceCalendars.sort(folderSort);
            function folderSort(a, b) {
                if (a == null) return -1;
                if (b == null) return 1;
                if (a.isSharedItem && !b.isSharedItem) return 1;
                if (b.isSharedItem && !a.isSharedItem) return -1;
                if (!a.isSharedItem && a.isPrimary && !b.isPrimary) return -1;
                if (!b.isSharedItem && b.isPrimary && !a.isPrimary) return 1;
                if (a.isDomainResource && !b.isDomainResource) return 1;
                if (b.isDomainResource && !a.isDomainResource) return -1;
                return caseInsensitiveStrCompare(a.name, b.name);

                function caseInsensitiveStrCompare(x, y) {
                    x = (x === undefined || x === null) ? '' : x;
                    y = (y === undefined || y === null) ? '' : y;
                    return x.localeCompare(y, undefined, { sensitivity: 'base' });
                }
            }
        }

        function getAppointmentName(type) {
            return type.name;
        }

        function onGetSourcesFailure(result) {
            return result;
        }

        function newAppointment(ev, form) {
            var appointmentExists = vm.appointmentTypes.length > 1;
            $mdDialog.show({
                locals: {
                    title: "",
                    appointDuration: 0,
                    newItem: true,
                    appointLocation: "",
                    isOnline: false,
                    bookingHorizonLimit: 0,
                    isUsingDifferentDescription: false,
                    description: "",
                    icsDescription: "",
                    appointmentExists: appointmentExists,
                },
                controller: "userEditAppointmentTypeController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/user-settings/scheduling-settings/edit-appointmentType.dlg.html",
                targetEvent: ev
            })
                .then(function (modalSuccess) {
                    vm.appointmentTypes.push({
                        name: modalSuccess.appointmentType.name,
                        duration: modalSuccess.appointmentType.duration,
                        location: modalSuccess.appointmentType.location,
                        isOnline: modalSuccess.appointmentType.isOnline,
                        bookingHorizonLimit: modalSuccess.appointmentType.bookingHorizonLimit,
                        isUsingDifferentDescription: modalSuccess.appointmentType.isUsingDifferentDescription,
                        description: modalSuccess.appointmentType.description,
                        icsDescription: modalSuccess.appointmentType.icsDescription
                    });
                    vm.appointmentTypes.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    form.$setDirty();
                }, function () {

                });
        }

        function editAppointment(ev, item, form) {
            var appointmentExists = vm.appointmentTypes.length > 1;
            $mdDialog.show({
                locals: {
                    title: item.name,
                    appointDuration: item.duration,
                    newItem: false,
                    appointLocation: item.location,
                    isOnline: item.isOnline,
                    bookingHorizonLimit: item.bookingHorizonLimit,
                    isUsingDifferentDescription: item.isUsingDifferentDescription,
                    description: item.description,
                    icsDescription: item.icsDescription,
                    appointmentExists: appointmentExists,
                },
                controller: "userEditAppointmentTypeController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/user-settings/scheduling-settings/edit-appointmentType.dlg.html",
                targetEvent: ev
            }).then(function (modalSuccess) {
                if (modalSuccess.deleteItem) {
                    appointmentDeleteItem(item, form);
                    return;
                }
                else {
                    modalSuccess.appointmentType.id = item.id;
                    appointmentDeleteItem(item, form);
                    vm.appointmentTypes.push(modalSuccess.appointmentType);
                    vm.appointmentTypes.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    form.$setDirty();
                }
            });
        }

        function appointmentDeleteItem(type, form) {
            var index = vm.appointmentTypes.indexOf(type);
            if (index !== -1)
                vm.appointmentTypes.splice(index, 1);
            vm.appointmentTypes.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            form.$setDirty();
        }

        function newCalendarConflict(ev, form) {
            var conCalendars = vm.sourceCalendars;
            conCalendars = conCalendars.filter(function (obj) {
                return obj.id !== vm.calendar;
            });

            var final = [];
            for (var i = 0; i < conCalendars.length; i++) {
                for (var j = 0; j < vm.conflictCalendars.length; j++) {
                    if (conCalendars[i].id == vm.conflictCalendars[j].id) {
                        final.push(conCalendars[i]);
                    }
                }
            }

            conCalendars = conCalendars.filter(function (obj) {
                return !final.includes(obj);
            });

            $mdDialog.show({
                locals: {
                    calendars: conCalendars,
                    newItem: true,
                    conflictCalendar: null,
                },
                controller: "userEditConflictCalendarController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/user-settings/scheduling-settings/edit-calendar-conflict.dlg.html",
                targetEvent: ev
            })
                .then(function (modalSuccess) {
                    vm.conflictCalendars.push(modalSuccess.conflictCalendar);
                    vm.conflictCalendars.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    form.$setDirty();
                }, function () {

                });
        }

        function editCalendarConflict(ev, item, form) {
            var conCalendars = vm.sourceCalendars;
            conCalendars = conCalendars.filter(function (obj) {
                return obj.id !== vm.calendar;
            });

            var final = [];
            for (var i = 0; i < conCalendars.length; i++) {
                for (var j = 0; j < vm.conflictCalendars.length; j++) {
                    if (conCalendars[i].id == vm.conflictCalendars[j].id && conCalendars[i].id !== item.id) {
                        final.push(conCalendars[i]);
                    }
                }
            }

            conCalendars = conCalendars.filter(function (obj) {
                return !final.includes(obj);
            });

            $mdDialog.show({
                locals: {
                    calendars: conCalendars,
                    newItem: false,
                    conflictCalendar: { id: item.id, name: item.name },
                },
                controller: "userEditConflictCalendarController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/user-settings/scheduling-settings/edit-calendar-conflict.dlg.html",
                targetEvent: ev
            })
                .then(function (modalSuccess) {
                    if (modalSuccess.deleteItem) {
                        calendarConflictDeleteItem(item, form);
                        return;
                    } else {
                        calendarConflictDeleteItem(item, form);
                        vm.conflictCalendars.push(modalSuccess.conflictCalendar);
                        vm.conflictCalendars.sort(function (a, b) {
                            return a.name.localeCompare(b.name);
                        });
                        form.$setDirty();
                    }
                }, function () {

                });
        }

        function calendarConflictDeleteItem(conflict, form) {
            var index = vm.conflictCalendars.indexOf(conflict);
            if (index !== -1)
                vm.conflictCalendars.splice(index, 1);
            vm.conflictCalendars.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            form.$setDirty();
        }
    }

})();